import * as Yup from 'yup';
import { texts } from './texts';

const requiredString = texts.requiredField;

const LoginSchema = Yup.object().shape({
  email: Yup.string().email(texts.emailFormatError).required(requiredString),
  password: Yup.string().required(requiredString),
});

const ProjectSchema = Yup.object().shape({
  name: Yup.string().required(requiredString),
  certificatePath: Yup.string().required(requiredString),
  instrumentPath: Yup.string().required(requiredString),
  transactionPath: Yup.string().required(requiredString),
});

const EnvironmentSchema = Yup.object().shape({
  url: Yup.string().required(requiredString),
  alias: Yup.string().required(requiredString),
});

const schemas = {
  LoginSchema,
  ProjectSchema,
  EnvironmentSchema,
};

export default schemas;
