import { config } from '../config/config';
import { CreateEnvironmentRequest, UpdateEnvironmentRequest } from '../store/environmentsSlice';
import axios from './axios';

export const environmentsService = {
  createEnvironment,
  updateEnvironment,
};

export async function createEnvironment(request: CreateEnvironmentRequest) {
  const res = await axios.post(`${config.backendUrl}/environments`, request);

  return res.data;
}

export async function updateEnvironment(request: UpdateEnvironmentRequest) {
  const res = await axios.put(`${config.backendUrl}/environments/${request.environmentId}`, request);

  return res.data;
}
