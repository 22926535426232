import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Field } from 'formik';
import FormTextField from '../components/common/forms/FormTextField';
import AuthLayout from '../components/auth/AuthLayout';
import schemas from '../data/schemas';
import { texts } from '../data/texts';
import { login } from '../store/authSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';

interface LoginValues {
  email: string;
  password: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginForm: {
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

function Login() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const isLoggedIn: boolean = useAppSelector((state: RootState) => state.auth.loggedIn);

  const submitLogin = (values: LoginValues) => dispatch(login(values));

  useEffect(() => {
    const cookies = new Cookies();
    if (isLoggedIn && cookies.get('token')) navigate('/projects');
  }, [isLoggedIn, navigate]);

  return (
    <main className={classes.loginForm}>
      <div>
        <AuthLayout
          initialValues={{ email: '', password: '' }}
          validationSchema={schemas.LoginSchema}
          onSubmit={submitLogin}
          buttonContent={texts.send}
        >
          <Field label="Email" name="email" component={FormTextField} />
          <Field label="Contraseña" name="password" type="password" component={FormTextField} />
        </AuthLayout>
      </div>
    </main>
  );
}

export default Login;
