import { MouseEventHandler } from 'react';
import { colors } from '../../../data/colors';

interface ChevronDownProps {
  className: string;
  onClick: MouseEventHandler<SVGSVGElement>;
}

function ChevronDown({ className, onClick }: ChevronDownProps) {
  return (
    <svg
      id="Icons_chevron-down"
      data-name="Icons / chevron-down"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      onClick={onClick}
    >
      <path id="Path_399" data-name="Path 399" d="M0,0H24V24H0Z" fill="none" />
      <path
        id="Path_400"
        data-name="Path 400"
        d="M6,9l6,6,6-6"
        fill="none"
        stroke={colors.grays.subtitleGray}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
}

export default ChevronDown;
