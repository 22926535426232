import { Dispatch, SetStateAction, ReactNode } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { colors } from '../../../data/colors';
import CustomButton, { CustomButtonVariant } from '../buttons/CustomButton';

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      padding: '0.5rem 0.5rem 1.5rem',
      boxShadow: `0 3px 6px ${colors.grays.shadowGraySmooth}`,
      borderRadius: '5px',
    },
    cross: {
      textAlign: 'end',
      color: colors.grays.inputGray,
      '& button': { padding: 0 },
      '& svg': { fill: colors.grays.inputGray },
    },
    bodyPadding: { paddingInline: '2rem' },
    title: {
      padding: 0,
      marginBlock: '1rem',
      '& h2': { font: 'normal normal bold 18px/22px Rubik' },
    },
    noSubtitle: { marginBottom: '3rem' },
    titleAboveContent: { marginBottom: '1.5rem' },
    subtitle: {
      marginBlock: '0 1.5rem',
      '& h2': {
        textAlign: 'center',
        font: 'normal normal normal 14px/17px Rubik',
        color: colors.grays.subtitleGray,
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
    },
    actions: {
      display: 'flex',
      flexDirection: 'row',
      gap: '1rem',
      padding: 0,
    },
  }),
);

interface CustomDialogProps {
  title: string | JSX.Element;
  subtitle?: string;
  content?: string | JSX.Element;
  hasCross?: boolean;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  cancelContent?: string | JSX.Element;
  proceedButton: ButtonProps;
  maxWidth?: 'xs' | 'sm' | false;
  alignTitle?: 'center' | 'left';
  justifyActions?: 'center' | 'right';
  children?: ReactNode;
}

interface ButtonProps {
  content: string | JSX.Element;
  handler?: (e?: any) => void;
  form?: string;
  variant?: CustomButtonVariant;
  type?: string;
  disabled?: boolean;
}

function CustomDialog({
  open,
  setOpen,
  title,
  subtitle,
  hasCross = true,
  cancelContent,
  proceedButton,
  children,
  maxWidth = 'xs',
  alignTitle = 'center',
  justifyActions = 'center',
}: CustomDialogProps) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth={maxWidth}
      onClose={() => setOpen(false)}
      classes={{ paper: classes.dialog }}
    >
      {hasCross && (
        <div className={classes.cross}>
          <IconButton onClick={() => setOpen(false)} color="primary">
            <CloseIcon />
          </IconButton>
        </div>
      )}
      <Box className={classes.bodyPadding}>
        <DialogTitle
          className={`${classes.title} ${!subtitle && classes.noSubtitle} ${
            children && !subtitle && classes.titleAboveContent
          }`}
          style={{ textAlign: alignTitle }}
        >
          {title}
        </DialogTitle>
        {subtitle && <DialogTitle className={`${classes.title} ${classes.subtitle}`}>{subtitle}</DialogTitle>}
        {children && <DialogContent className={classes.content}>{children}</DialogContent>}

        <DialogActions className={classes.actions} style={{ justifyContent: justifyActions }}>
          {cancelContent && (
            <CustomButton onClick={() => setOpen(false)} variant={CustomButtonVariant.SECONDARY}>
              {cancelContent}
            </CustomButton>
          )}
          <CustomButton
            onClick={proceedButton.handler}
            variant={proceedButton.variant}
            form={proceedButton.form}
            type={proceedButton.type}
            disabled={proceedButton.disabled}
          >
            {proceedButton.content}
          </CustomButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default CustomDialog;
