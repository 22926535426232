import { Paper } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Status } from '../../config/enum';
import schemas from '../../data/schemas';
import { texts } from '../../data/texts';
import {
  createEnvironment,
  CreateEnvironmentRequest,
  EnvironmentsState,
  updateEnvironment,
  UpdateEnvironmentRequest,
} from '../../store/environmentsSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ProjectsState } from '../../store/projectsSlice';
import { AppDispatch, RootState } from '../../store/store';
import { Environment } from '../../types/environment.types';
import FormTextField from '../common/forms/FormTextField';
import CustomSnackbar from '../CustomSnackbar/CustomSnackbar';

interface EnvironmentFormProps {
  selectedEnvironmentId?: string;
  formId?: string;
}

interface EnvironmentValues {
  url: string;
  alias: string;
}

function EnvironmentForm({ selectedEnvironmentId, formId }: EnvironmentFormProps) {
  const projectsState: ProjectsState = useAppSelector((state: RootState) => state.projects);
  const environmentsState: EnvironmentsState = useAppSelector((state: RootState) => state.environments);
  const dispatch: AppDispatch = useAppDispatch();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selectedEnvironment, setSelectedEnvironment] = useState<Environment | null>(null);

  useEffect(() => {
    if (selectedEnvironmentId) {
      if (environmentsState.updatedEnvironment) {
        setSelectedEnvironment(environmentsState.updatedEnvironment);
      } else {
        setSelectedEnvironment(
          projectsState.selectedProject!.environments?.find(
            (environment: Environment) => environment.environmentId === selectedEnvironmentId!,
          )!,
        );
      }
    }
  }, [
    selectedEnvironment,
    selectedEnvironmentId,
    environmentsState.updatedEnvironment,
    projectsState.selectedProject,
  ]);

  const snackbarIsOpen: boolean =
    submitting &&
    (environmentsState.createEnvironmentSuccess ||
      environmentsState.createEnvironmentErrorMessage !== null ||
      environmentsState.updateEnvironmentSuccess ||
      environmentsState.updateEnvironmentErrorMessage !== null);
  const snackbarSuccessText: string = `Se ${
    selectedEnvironment ? 'modificó' : 'creó'
  } el ambiente correctamente`;
  const snackbarMessage: string =
    environmentsState.createEnvironmentSuccess || environmentsState.updateEnvironmentSuccess
      ? snackbarSuccessText
      : environmentsState.createEnvironmentErrorMessage! || environmentsState.updateEnvironmentErrorMessage!;
  const status: Status =
    environmentsState.createEnvironmentSuccess || environmentsState.updateEnvironmentSuccess
      ? Status.SUCCESS
      : Status.ERROR;

  const submitEnvironment = (values: EnvironmentValues) => {
    setSubmitting(true);
    if (selectedEnvironment) {
      const environmentId: string = selectedEnvironment.environmentId;
      const updateEnvironmentRequest: UpdateEnvironmentRequest = { ...values, environmentId };
      dispatch(updateEnvironment(updateEnvironmentRequest));
    } else {
      const projectId: string = projectsState.selectedProject?.projectId!;
      const createEnvironmentRequest: CreateEnvironmentRequest = { ...values, projectId };
      dispatch(createEnvironment(createEnvironmentRequest));
    }
  };

  const closeSnack = () => setSubmitting(false);

  return (
    <Paper elevation={0} className="paper">
      <Formik
        initialValues={{
          url: selectedEnvironment ? selectedEnvironment.url : '',
          alias: selectedEnvironment ? selectedEnvironment.alias : '',
        }}
        validationSchema={schemas.EnvironmentSchema}
        onSubmit={submitEnvironment}
        enableReinitialize
      >
        <Form id={formId} className="form">
          <div className="form-row">
            <Field label={texts.url} name="url" component={FormTextField} type="text" />
            <Field label={texts.alias} name="alias" component={FormTextField} type="text" />
          </div>
          <CustomSnackbar
            open={snackbarIsOpen}
            message={snackbarMessage}
            handleClose={closeSnack}
            type={status}
          />
        </Form>
      </Formik>
    </Paper>
  );
}

export default EnvironmentForm;
