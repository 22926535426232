import { BrowserRouter } from 'react-router-dom';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { colors } from '../src/data/colors';
import Main from './screens/Main';
import './styles/app.scss';
import { store } from './store/store';

const theme = createTheme({
  palette: {
    primary: { main: '#3c3c3c' },
    secondary: { main: '#256FB5' },
    error: {
      main: '#f11930',
      light: '#f9ecec',
      dark: colors.reds.red,
    },
    warning: {
      main: '#ffc400',
      light: '#fff9e5',
      dark: '#ffc400',
    },
    success: {
      main: '#529252',
      light: '#edf4ed',
      dark: '#529252',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
  },
});

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>
          <Main />
        </MuiThemeProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
