import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { projectsService } from '../services/projects';
import { Project } from '../types/project.types';
import { logout } from './authSlice';

export interface CreateProjectRequest {
  name: string;
  certificatePath: string;
  instrumentPath: string;
  transactionPath: string;
}

export interface UpdateProjectRequest {
  projectId: string;
  name: string;
  certificatePath: string;
  instrumentPath: string;
  transactionPath: string;
}

export interface ProjectsState {
  projects: Project[];
  loading: boolean;
  errorMessage: string | null;
  selectedProject: Project | null;
  gettingProject: boolean;
  getProjectSuccess: boolean;
  getProjectErrorMessage: string | null;
  creatingProject: boolean;
  createProjectSuccess: boolean;
  createProjectErrorMessage: string | null;
  updatingProject: boolean;
  updateProjectSuccess: boolean;
  updateProjectErrorMessage: string | null;
}

const initialState: ProjectsState = {
  projects: [],
  loading: false,
  errorMessage: null,
  selectedProject: null,
  gettingProject: false,
  getProjectSuccess: false,
  getProjectErrorMessage: null,
  creatingProject: false,
  createProjectSuccess: false,
  createProjectErrorMessage: null,
  updatingProject: false,
  updateProjectSuccess: false,
  updateProjectErrorMessage: null,
};

export const fetchProjects = createAsyncThunk('projects', async () => {
  const response = await projectsService.getProjects();

  return response.data;
});

export const getProjectById = createAsyncThunk('getProjectById', async (projectId: string) => {
  const response = await projectsService.getProjectById(projectId);

  return response.data;
});

export const createProject = createAsyncThunk('createProject', async (data: CreateProjectRequest) => {
  const response = await projectsService.createProject(data);

  return response.data;
});

export const updateProject = createAsyncThunk('updateProject', async (data: UpdateProjectRequest) => {
  const response = await projectsService.updateProject(data);

  return response.data;
});

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<ProjectsState>) => {
    builder.addCase(fetchProjects.pending, (state: ProjectsState, _action) => {
      state.projects = [];
      state.loading = true;
      state.errorMessage = null;
    });
    builder.addCase(fetchProjects.fulfilled, (state: ProjectsState, action) => {
      state.projects = action.payload;
      state.loading = false;
      state.errorMessage = null;
    });
    builder.addCase(fetchProjects.rejected, (state: ProjectsState, action) => {
      state.projects = [];
      state.loading = false;
      state.errorMessage = action.error.message!;
    });
    builder.addCase(createProject.pending, (state: ProjectsState, _action) => {
      state.creatingProject = true;
      state.createProjectSuccess = false;
      state.createProjectErrorMessage = null;
    });
    builder.addCase(createProject.fulfilled, (state: ProjectsState, action) => {
      const projectsWithNew: Project[] = state.projects ? [...state.projects] : [];
      projectsWithNew?.push(action.payload);

      state.projects = projectsWithNew;
      state.creatingProject = false;
      state.createProjectSuccess = true;
      state.createProjectErrorMessage = null;
    });
    builder.addCase(createProject.rejected, (state: ProjectsState, action) => {
      state.creatingProject = false;
      state.createProjectSuccess = false;
      state.createProjectErrorMessage = action.error.message!;
    });
    builder.addCase(updateProject.pending, (state: ProjectsState, _action) => {
      state.updatingProject = true;
      state.updateProjectSuccess = false;
      state.updateProjectErrorMessage = null;
    });
    builder.addCase(updateProject.fulfilled, (state: ProjectsState, action) => {
      state.projects = state.projects.filter(
        (project: Project) => project.projectId !== action.payload.projectId,
      );
      state.projects.push(action.payload);
      state.updatingProject = false;
      state.updateProjectSuccess = true;
      state.updateProjectErrorMessage = null;
    });
    builder.addCase(updateProject.rejected, (state: ProjectsState, action) => {
      state.updatingProject = false;
      state.updateProjectSuccess = false;
      state.updateProjectErrorMessage = action.error.message!;
    });
    builder.addCase(getProjectById.pending, (state: ProjectsState, _action) => {
      state.gettingProject = true;
      state.getProjectSuccess = false;
      state.getProjectErrorMessage = null;
    });
    builder.addCase(getProjectById.fulfilled, (state: ProjectsState, action) => {
      state.selectedProject = action.payload;
      state.gettingProject = false;
      state.getProjectSuccess = true;
      state.getProjectErrorMessage = null;
    });
    builder.addCase(getProjectById.rejected, (state: ProjectsState, action) => {
      state.gettingProject = false;
      state.getProjectSuccess = false;
      state.getProjectErrorMessage = action.error.message!;
    });
    builder.addCase(logout.fulfilled, () => initialState);
  },
});

const { reducer } = projectsSlice;

export default reducer;
