import { Typography, makeStyles, createStyles, Theme } from '@material-ui/core';
import { colors } from '../../data/colors';
import { texts } from '../../data/texts';
import logo from '../../assets/img/logo.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      backgroundColor: theme.palette.primary.main,
      color: colors.white,
      padding: '15px 30px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': { height: '50px' },
      '& p': {
        marginTop: 7,
        fontSize: 14,
        fontStyle: 'italic',
        color: colors.grays.formGray,
      },
    },
  }),
);

const AuthHeader = () => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <img src={logo} alt={texts.plexoLogo} />
      <Typography>{texts.proxyModule}</Typography>
    </div>
  );
};

export default AuthHeader;
