import { makeStyles, Slide, Snackbar, SnackbarContent, Theme } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { PaletteColor } from '@material-ui/core/styles/createPalette';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { SyntheticEvent } from 'react';
import { Status } from '../../config/enum';

const useSnackbarStyles = makeStyles({
  snackbar: { maxWidth: 600 },
  message: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: { marginRight: 15 },
});

interface CustomSnackbarProps {
  open: boolean;
  message: string;
  autoHide?: number;
  handleClose(event: SyntheticEvent<any>): void;
  type: Status;
}

function CustomSnackbar({ open, message, autoHide, handleClose, type }: CustomSnackbarProps) {
  const classes = useSnackbarStyles();
  const theme: Theme = useTheme();
  const statusIcon: JSX.Element = type === Status.SUCCESS ? <CheckCircleIcon /> : <ErrorIcon />;

  let statusColor: PaletteColor;
  switch (type) {
    case Status.SUCCESS:
      statusColor = theme.palette.success;
      break;
    case Status.ERROR:
      statusColor = theme.palette.error;
      break;
    default:
      statusColor = theme.palette.error;
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHide || 2000}
      TransitionComponent={Slide}
      onClose={handleClose}
      classes={{ root: classes.snackbar }}
    >
      <SnackbarContent
        classes={{ message: classes.message }}
        style={{ backgroundColor: statusColor.main }}
        message={
          <>
            <span className={classes.icon}>{statusIcon}</span>
            {message}
          </>
        }
      />
    </Snackbar>
  );
}

export default CustomSnackbar;
