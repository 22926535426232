import { useState } from 'react';
import {
  FormControl,
  InputLabel,
  TextField,
  InputAdornment,
  FormHelperText,
  IconButton,
  withStyles,
  Theme,
} from '@material-ui/core';
import { FieldInputProps, FormikValues } from 'formik';
import EyeIcon from '../../../assets/img/eye_icon.svg';
import EyeSlashedIcon from '../../../assets/img/eye-slash.svg';

const styles = (theme: Theme) => ({ formControl: { margin: `${theme.spacing(1)}px 0` } });

interface FormTextFieldProps {
  field?: FieldInputProps<FormikValues>;
  form?: any;
  classes: any;
  label: string;
  noFullWidth?: boolean;
  type?: string;
  value?: string | number;
  disabled?: boolean;
  placeholder?: string;
  onChange?: (e: any) => void;
  disableIcon?: boolean;
}

function FormTextField({
  field,
  form,
  classes,
  label,
  noFullWidth,
  type,
  value,
  onChange,
  disabled,
  placeholder,
  disableIcon = false,
}: FormTextFieldProps) {
  const hasError: boolean = form?.touched?.[field?.name!] && form?.errors?.[field?.name!];
  const isPassword: boolean = type === 'password';
  const [inputType, setInputType] = useState<string>(type || 'text');
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setInputType(!showPassword ? 'text' : 'password');
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => event.preventDefault();

  const passwordAdornment = (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
      >
        <img
          src={showPassword ? EyeIcon : EyeSlashedIcon}
          alt={showPassword ? 'Eye icon' : 'Eye icon slashed'}
        />
      </IconButton>
    </InputAdornment>
  );

  return (
    <FormControl variant="outlined" className={classes.formControl} fullWidth={!noFullWidth}>
      <InputLabel className="input-label" shrink={true}>
        {label}
      </InputLabel>
      <TextField
        margin="none"
        variant="outlined"
        size="small"
        error={hasError}
        InputProps={{
          classes: {
            input: 'input-element',
            disabled: 'input-disabled',
          },
          endAdornment: isPassword && !disableIcon && passwordAdornment,
        }}
        {...field}
        onChange={onChange || field?.onChange}
        disabled={disabled}
        type={inputType}
        placeholder={placeholder}
        value={value || field?.value}
      />
      {hasError && (
        <FormHelperText className="input-error" variant="standard">
          {form?.errors?.[field?.name!]}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default withStyles(styles)(FormTextField);
