export const texts = {
  new: 'Nuevo',
  edit: 'Editar',
  send: 'Enviar',
  select: 'Seleccionar',
  proxyModule: 'Proxy',
  loginButtonText: 'Iniciar sesión',
  loginFailed: 'No se pudo iniciar sesión con las credenciales provistas',
  plexoLogo: 'Logo de Plexo',
  user: 'Usuario',
  userName: 'Nombre de usuario',
  password: 'Contraseña',
  save: 'Guardar',
  saveChanges: 'Guardar cambios',
  cancel: 'Cancelar',
  delete: 'Eliminar',
  confirm: 'Confirmar',
  seeDetails: 'Ver detalles',
  exit: 'Salir',
  search: 'Buscar',
  retry: 'Reintentar',
  configuration: 'Configuración',
  projects: 'Proyectos',
  newProject: 'Nuevo projecto',
  environments: 'Ambientes',
  newEnvironment: 'Nuevo ambiente',
  goBack: 'Volver',
  requiredField: 'Campo requerido',
  emailFormatError: 'Debe tener formato de email',
  name: 'Nombre',
  certificatePath: 'Directorio del certificado',
  instrumentPath: 'Instrument path',
  transactionPath: 'Transaction path',
  logoutQuestion: '¿Estás seguro que quieres cerrar tu sesión?',
  logoutConfirmation: 'Sí, cerrar sesión',
  url: 'Url',
  alias: 'Alias',
};
