import Lottie from 'react-lottie';
import loadingAnimation from '../../../assets/animations/loadAnimation.json';

export interface LoadingProps {
  size?: number;
}

function Loading({ size }: LoadingProps) {
  return (
    <div>
      <Lottie
        height={size ? size : 300}
        width={size ? size : 300}
        isClickToPauseDisabled={true}
        options={{ animationData: loadingAnimation, loop: true }}
      />
    </div>
  );
}

export default Loading;
