import { NavLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { createStyles, ListItem, ListItemIcon, makeStyles, Theme } from '@material-ui/core';
import { colors } from '../../../data/colors';
import { NavigationObject } from '../../../data/navigation';
import HomeAltIcon from '../icons/HomeAltIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    activeNav: {
      fontWeight: 500,
      color: theme.palette.secondary.main,
    },
    sidebarItem: {
      padding: '1rem 2rem',
      borderLeft: '3px solid transparent',
      '&:hover': { borderRadius: '0 10px 10px 0' },
    },
    activeSidebarItem: {
      padding: '1rem 2rem',
      borderLeft: `3px solid ${theme.palette.secondary.main}`,
      background: colors.blues.sidebarBlue,
      borderRadius: '0 10px 10px 0',
      '&:hover': { background: colors.blues.sidebarBlue },
    },
    sidebarSubItem: {
      padding: '1rem 1rem 1rem 3rem',
      borderLeft: '3px solid transparent',
      '&:hover': { borderRadius: '0 10px 10px 0' },
    },
    activeSidebarSubItem: {
      padding: '1rem 1rem 1rem 3rem',
      borderLeft: `3px solid ${theme.palette.secondary.main}`,
      background: colors.blues.sidebarBlue,
      borderRadius: '0 10px 10px 0',
      '&:hover': { background: colors.blues.sidebarBlue },
    },
    listItemIcon: {
      minWidth: 40,
    },
    sidebarText: {
      fontSize: 14,
      marginLeft: 8,
    },
  }),
);

interface SidebarNavigationItemsProps {
  navigationArray: NavigationObject[];
  isSubset?: boolean;
}

type PropsType = SidebarNavigationItemsProps;

function SidebarNavigationItems({ navigationArray, isSubset = false }: PropsType) {
  const classes = useStyles();
  const location = useLocation();

  const getRouteIcon = (route: string, active: boolean) => {
    switch (route) {
      case '/projects':
        return <HomeAltIcon active={active} />;

      default:
        return <HomeAltIcon active={active} />;
    }
  };

  return (
    <>
      {navigationArray.map((navigationObject: NavigationObject, index: number) => {
        const actualLocation: string = location.pathname;
        const isActiveItem: boolean = navigationObject.to === actualLocation;

        return (
          <NavLink
            key={index}
            to={navigationObject.to}
            className={clsx(classes.link, { [classes.activeNav]: isActiveItem })}
          >
            <ListItem
              className={
                isSubset
                  ? isActiveItem
                    ? classes.activeSidebarSubItem
                    : classes.sidebarSubItem
                  : isActiveItem
                  ? classes.activeSidebarItem
                  : classes.sidebarItem
              }
              button
            >
              <ListItemIcon classes={{ root: classes.listItemIcon }}>
                {getRouteIcon(navigationObject.to, isActiveItem)}
              </ListItemIcon>
              <span className={classes.sidebarText}>{navigationObject.name}</span>
            </ListItem>
          </NavLink>
        );
      })}
    </>
  );
}

export default SidebarNavigationItems;
