import { Navigate, Outlet } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { colors } from '../../data/colors';
import { measures } from '../../data/measures';
import { useAppSelector } from '../../store/hooks';
import { RootState } from '../../store/store';
import Header from './Header';
import Sidebar from './sidebar/Sidebar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      marginTop: measures['headerHeight'],
      position: 'relative',
      marginLeft: measures['expandedWidth'],
      backgroundColor: colors.grays.mainBackground,
      minHeight: '100vh',
    },
  }),
);

const MainLayout = () => {
  const classes = useStyles();
  const isLoggedIn: boolean = useAppSelector((state: RootState) => state.auth.loggedIn);

  return isLoggedIn ? (
    <main className={classes.content}>
      <Header />
      <Sidebar />
      <Outlet />
    </main>
  ) : (
    <Navigate to="/login" replace />
  );
};

export default MainLayout;
