import { LoginRequest } from '../types/auth.types';
import { config } from '../config/config';
import axios from './axios';

export const authService = {
  login,
  logout,
};

async function login(loginRequest: LoginRequest) {
  const res = await axios.post(`${config.backendUrl}/auth/login`, loginRequest);
  return res.data;
}

async function logout() {
  const res = await axios.post(`${config.backendUrl}/auth/logout`);
  return res.data;
}
