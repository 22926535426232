import { Paper } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { Status } from '../../config/enum';
import schemas from '../../data/schemas';
import { texts } from '../../data/texts';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { createProject, ProjectsState, updateProject } from '../../store/projectsSlice';
import { AppDispatch, RootState } from '../../store/store';
import { Project } from '../../types/project.types';
import FormTextField from '../common/forms/FormTextField';
import CustomSnackbar from '../CustomSnackbar/CustomSnackbar';

interface ProjectFormProps {
  selectedProject?: Project;
  formId?: string;
}

function ProjectForm({ selectedProject, formId }: ProjectFormProps) {
  const dispatch: AppDispatch = useAppDispatch();
  const projects: ProjectsState = useAppSelector((state: RootState) => state.projects);
  const [submittingProject, setSubmittingProject] = useState<boolean>(false);

  const snackbarIsOpen: boolean =
    submittingProject &&
    (projects.createProjectSuccess ||
      projects.createProjectErrorMessage !== null ||
      projects.updateProjectSuccess ||
      projects.updateProjectErrorMessage !== null);
  const snackbarSuccessText: string = `Se ${selectedProject ? 'modificó' : 'creó'} el proyecto correctamente`;
  const snackbarMessage: string =
    projects.createProjectSuccess || projects.updateProjectSuccess
      ? snackbarSuccessText
      : projects.createProjectErrorMessage! || projects.updateProjectErrorMessage!;
  const status: Status =
    projects.createProjectSuccess || projects.updateProjectSuccess ? Status.SUCCESS : Status.ERROR;

  const submitProject = (values: Project) => {
    setSubmittingProject(true);
    dispatch(selectedProject ? updateProject(values) : createProject(values));
  };

  const closeSnack = () => setSubmittingProject(false);

  return (
    <Paper elevation={0} className="paper">
      <Formik
        initialValues={{
          projectId: selectedProject ? selectedProject.projectId : '',
          name: selectedProject ? selectedProject.name : '',
          certificatePath: selectedProject ? selectedProject.certificatePath : '',
          instrumentPath: selectedProject ? selectedProject.instrumentPath : '',
          transactionPath: selectedProject ? selectedProject.transactionPath : '',
        }}
        validationSchema={schemas.ProjectSchema}
        onSubmit={submitProject}
        enableReinitialize
      >
        <Form id={formId} className="form">
          <div className="form-row">
            <Field label={texts.name} name="name" component={FormTextField} type="text" />
            <Field
              label={texts.certificatePath}
              name="certificatePath"
              component={FormTextField}
              type="text"
            />
          </div>
          <div className="form-row">
            <Field label={texts.instrumentPath} name="instrumentPath" component={FormTextField} type="text" />
            <Field
              label={texts.transactionPath}
              name="transactionPath"
              component={FormTextField}
              type="text"
            />
          </div>
          <CustomSnackbar
            open={snackbarIsOpen}
            message={snackbarMessage}
            handleClose={closeSnack}
            type={status}
          />
        </Form>
      </Formik>
    </Paper>
  );
}

export default ProjectForm;
