import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Cookies } from 'react-cookie';
import { config } from '../config/config';

const instance: AxiosInstance = axios.create({ baseURL: config.backendUrl });

// Append token on each request
instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const cookies = new Cookies();
    const bearerToken: string | undefined = cookies.get('token');
    if (bearerToken && config.headers !== undefined) {
      config.headers.Authorization = `Bearer ${bearerToken}`;
    }
    return config;
  },
  (error: AxiosError) => Promise.reject(error),
);

instance.interceptors.response.use(
  async (response: AxiosResponse) => {
    if (response?.data?.result === false) {
      const errorMessage: string = response?.data?.showMessage?.ES || 'error';

      return Promise.reject(errorMessage);
    }
    return response;
  },
  (error: AxiosError) => {
    // Delete token from localStorage if status is unauthorized
    if (error?.response?.status === 401) {
      const cookies = new Cookies();
      cookies.remove('token');
      window.location.reload();
    }
    return Promise.reject(error);
  },
);

export default instance;
