import { ReactNode } from 'react';
import { Button, createStyles, makeStyles } from '@material-ui/core';
import { colors } from '../../../data/colors';
import Loading from '../animations/Loading';

export enum CustomButtonVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DANGER = 'danger',
  DARK = 'dark',
}

interface CustomButtonProps {
  children: ReactNode;
  variant?: CustomButtonVariant;
  onClick?: (event?: any) => void;
  className?: string;
  type?: string;
  form?: string;
  selected?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

const baseStyles = {
  root: {
    boxShadow: 'none',
    padding: '0.6rem 0.75rem',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: '4px',
    font: 'normal normal normal 14px/17px Rubik',
  },
  disabled: { opacity: 0.5 },
};

const primaryStyles = {
  ...baseStyles,
  root: {
    ...baseStyles.root,
    borderColor: colors.greens.plexoGreen,
    color: colors.white,
    backgroundColor: colors.greens.plexoGreen,
    '&:hover': { backgroundColor: colors.white, color: colors.greens.plexoGreen },
  },
  disabled: {
    ...baseStyles.disabled,
    '&$root': { color: colors.white },
  },
};

const secondaryStyles = {
  ...baseStyles,
  root: {
    ...baseStyles.root,
    borderColor: colors.grays.buttonBorderGray,
    color: colors.grays.graphLegendsGray,
    backgroundColor: colors.white,
    '&:hover': { backgroundColor: colors.grays.buttonBorderGray },
  },
  disabled: {
    ...baseStyles.disabled,
    '&$root': { color: colors.grays.graphLegendsGray },
  },
};

const dangerStyles = {
  ...baseStyles,
  root: {
    ...baseStyles.root,
    color: colors.white,
    borderColor: colors.reds.lightRed,
    backgroundColor: colors.reds.lightRed,
    '&:hover': { backgroundColor: colors.white, color: colors.reds.errorRed },
  },
  disabled: {
    ...baseStyles.disabled,
    '&$root': { color: colors.white },
  },
};

const darkStyles = {
  ...baseStyles,
  root: {
    ...baseStyles.root,
    color: colors.white,
    borderColor: colors.white,
    backgroundColor: 'transparent',
    '&:hover': { backgroundColor: colors.grays.buttonBorderGray, color: colors.grays.graphLegendsGray },
  },
  disabled: {
    ...baseStyles.disabled,
    '&$root': { color: colors.white },
  },
};

const useStyles = (variant: CustomButtonVariant) => {
  let styles: any = null;
  switch (variant) {
    case CustomButtonVariant.PRIMARY:
      styles = primaryStyles;
      break;
    case CustomButtonVariant.SECONDARY:
      styles = secondaryStyles;
      break;
    case CustomButtonVariant.DANGER:
      styles = dangerStyles;
      break;
    case CustomButtonVariant.DARK:
      styles = darkStyles;
      break;
    default:
      styles = primaryStyles;
  }

  return makeStyles(() => createStyles(styles));
};

function CustomButton({
  children,
  onClick,
  disabled,
  loading = false,
  variant = CustomButtonVariant.PRIMARY,
  selected = false,
  form,
  type,
}: CustomButtonProps) {
  const classes = useStyles(variant)();

  return (
    <Button
      onClick={onClick}
      classes={{ root: classes.root, disabled: classes.disabled }}
      disabled={loading || disabled}
      type={type === 'submit' || form ? 'submit' : 'button'}
      form={form}
      style={
        variant === CustomButtonVariant.SECONDARY && selected
          ? { backgroundColor: colors.grays.buttonBorderGray }
          : undefined
      }
    >
      {loading ? <Loading size={50} /> : children}
    </Button>
  );
}

export default CustomButton;
