import { colors } from '../../../data/colors';

interface HomeAltIconProps {
  active?: boolean;
}

function HomeAltIcon({ active = false }: HomeAltIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14.001" viewBox="0 0 16 14.001">
      <path
        id="home-alt"
        d="M47.96,38.194l-7.277-5.938a1,1,0,0,0-1.262,0l-7.275,5.938a.25.25,0,0,0-.036.351l.316.388a.25.25,0,0,0,.35.037l1.277-1.043v7.595a.5.5,0,0,0,.5.5h4a.5.5,0,0,0,.5-.5v-4l2,.009v4a.5.5,0,0,0,.5.5l4-.01a.5.5,0,0,0,.5-.5v-7.6l1.277,1.043a.25.25,0,0,0,.352-.036L48,38.546a.25.25,0,0,0-.038-.352Zm-2.91,6.832h0l-3,.009V41.029a.5.5,0,0,0-.5-.5l-3-.008a.5.5,0,0,0-.5.5v4h-3V37.111l5-4.081,5,4.081Z"
        transform="translate(-32.054 -32.033)"
        fill={active ? colors.primary : colors.grays.subtitleGray}
      />
    </svg>
  );
}

export default HomeAltIcon;
