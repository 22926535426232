import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Cookies } from 'react-cookie';
import { authService } from '../services/auth';
import { User } from '../types/auth.types';

export const login = createAsyncThunk('auth/login', async (user: User) => {
  const response = await authService.login(user);
  const cookies = new Cookies();
  cookies.set('token', response!.data.token);
  return response.data;
});

export const logout = createAsyncThunk('auth/logout', async () => {
  const response = await authService.logout();
  const cookies = new Cookies();
  cookies.remove('token');
  return response.data;
});

interface AuthState {
  loggingIn: boolean;
  loggedIn: boolean;
  email: string | null;
  loginErrorMessage: string | null;
}

const initialState: AuthState = {
  loggingIn: false,
  loggedIn: false,
  email: null,
  loginErrorMessage: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<AuthState>) => {
    builder.addCase(login.pending, (state: AuthState, action) => {
      state.loggingIn = true;
      state.loggedIn = false;
      state.email = null;
      state.loginErrorMessage = null;
    });
    builder.addCase(login.fulfilled, (state: AuthState, action) => {
      state.loggingIn = false;
      state.loggedIn = true;
      state.email = action.payload;
      state.loginErrorMessage = null;
    });
    builder.addCase(login.rejected, (state: AuthState, action) => {
      state.loggingIn = false;
      state.loggedIn = false;
      state.email = null;
      state.loginErrorMessage = action.error.message!;
    });
    builder.addCase(logout.fulfilled, () => initialState);
  },
});

const { reducer } = authSlice;

export default reducer;
