import { colors } from '../../../data/colors';

function UserCircleIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31.95" height="31.95" viewBox="0 0 31.95 31.95">
      <path
        id="user-circle"
        d="M15.975,8A15.975,15.975,0,1,0,31.95,23.975,15.972,15.972,0,0,0,15.975,8ZM24.22,35.157a13.811,13.811,0,0,1-16.49,0v-.876a4.126,4.126,0,0,1,4.123-4.123c.715,0,1.771.734,4.123.734s3.4-.734,4.123-.734a4.126,4.126,0,0,1,4.123,4.123Zm1.971-1.771A6.164,6.164,0,0,0,20.1,28.1c-1.32,0-1.958.734-4.123.734s-2.8-.734-4.123-.734a6.164,6.164,0,0,0-6.094,5.288,13.914,13.914,0,1,1,20.432,0ZM15.975,15.214a5.668,5.668,0,1,0,5.668,5.668A5.669,5.669,0,0,0,15.975,15.214Zm0,9.276a3.607,3.607,0,1,1,3.607-3.607A3.61,3.61,0,0,1,15.975,24.49Z"
        transform="translate(0 -8)"
        fill={colors.blues.sidebarBlueFilled}
      />
    </svg>
  );
}

export default UserCircleIcon;
