const colors = {
  grays: {
    gray: '#dedede',
    notBlack: '#545454',
    almostBlack: '#343434',
    lightGray: '#f2f2f2',
    disabledGray: '#efefef',
    buttonBorderGray: '#d7dbec',
    borderGray: '#d0d6e8',
    inputGray: '#8b8b8b',
    inputDividerGray: '#e0e0e0',
    pillGray: '#41464b',
    pillGrayTransparent: '#41464b1a',
    graphLightGray: '#d5d7e3',
    graphStrokeGray: '#f1f1f5',
    graphLegendsGray: '#5a607f',
    subtitleGray: '#7e84a3',
    shadowGray: '#15223214',
    shadowGraySmooth: '#00000029',
    checkboxBorderInactive: '#b6b6b6',
    mainBackground: '#f4f7fc',
    formGray: '#cccccc',
  },
  blues: {
    darkBlue: '#1e385a',
    sidebarBlue: '#0048890d',
    sidebarBlueFilled: '#004889',
    graphBlue: '#1e5eff',
    graphSkyBlue: '#57b8ff',
  },
  greens: {
    green: '#4caf50',
    plexoGreen: '#cecf00',
    graphGreen: '#3dd598',
    toastGreen: '#eafff6',
    aquaGreen: '#1fd286',
    aquaGreenTransparent: '#1fd2861a',
  },
  reds: {
    red: '#c54343',
    errorRed: '#f44336',
    graphRed: '#f0142f',
    toastRed: '#ffd9d9',
    lightRed: '#ff0000',
    lightRedTransparent: '#ff00001a',
  },
  yellows: {
    yellow: '#e5aa51',
    lightYellow: '#ffeb3b',
    paleYellow: '#ffca4d',
    toastYellow: '#fff2d2',
    paleYellowTransparent: '#ffca4d1a',
    goldenYellow: '#c38900',
  },
  oranges: {
    orange: '#e59c2e',
    pillOrange: '#ff9300',
    pillOrangeTransparent: '#ff93001a',
  },
  purples: {
    purple: '#b45db7',
    graphPurple: '#8884d8',
  },
  chartColors: ['#0088fe', '#00C49f', '#ffbb28', '#'],
  white: '#ffffff',
  primary: '#4877b4',
  lightPrimary: '#5e91da',
  secondary: '#256fb5',
};

export { colors };
