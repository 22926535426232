import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useEffect } from 'react';
import EditIcon from '../../assets/svg/edit_icon.svg';
import EnvironmentsIcon from '../../assets/svg/environment_icon.svg';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchProjects, ProjectsState } from '../../store/projectsSlice';
import { AppDispatch, RootState } from '../../store/store';
import { Project } from '../../types/project.types';
import { TableItem } from '../../types/table.types';
import Loading from '../common/animations/Loading';

interface ProjectsTableProps {
  openProject: (openProject: Project) => void;
  openEnvironments: (project: Project) => void;
}

function ProjectsTable({ openProject, openEnvironments }: ProjectsTableProps) {
  const dispatch: AppDispatch = useAppDispatch();
  const projects: ProjectsState = useAppSelector((state: RootState) => state.projects);

  const tableRowName: TableItem[] = [
    { row: 'projectId', text: 'Id proyecto' },
    { row: 'name', text: 'Nombre' },
    { row: 'certificatePath', text: 'Directorio del certificado' },
    { row: 'instrumentPath', text: 'Instrument path' },
    { row: 'transactionPath', text: 'Transation path' },
  ];

  useEffect(() => {
    dispatch(fetchProjects());
  }, [dispatch]);

  const projectsRows = projects.projects.slice(0, 20).map((row: Project) => {
    return (
      <TableRow key={row.projectId} hover classes={{ root: 'table-row', hover: 'table-row-hover' }}>
        <TableCell align="center" classes={{ root: 'table-cell' }}>
          <Typography variant="body2">{row?.projectId}</Typography>
        </TableCell>
        <TableCell align="center" classes={{ root: 'table-cell' }}>
          {row?.name}
        </TableCell>
        <TableCell align="center" classes={{ root: 'table-cell' }}>
          {row?.certificatePath}
        </TableCell>
        <TableCell align="center" classes={{ root: 'table-cell' }}>
          {row?.instrumentPath}
        </TableCell>
        <TableCell align="center" classes={{ root: 'table-cell' }}>
          {row?.transactionPath}
        </TableCell>
        <TableCell classes={{ root: 'table-cell actions-cell' }} align="right">
          <div className="icons-row-cell">
            <div className="squared-icon" onClick={() => openProject(row)}>
              <img src={EditIcon} alt="edit" />
            </div>
            <div className="squared-icon" onClick={() => openEnvironments(row)}>
              <img src={EnvironmentsIcon} alt="environments" />
            </div>
          </div>
        </TableCell>
      </TableRow>
    );
  });

  if (projects.loading) {
    return <Loading />;
  }

  return (
    <Paper classes={{ root: 'table-paper-container' }} elevation={0}>
      <TableContainer className="table-container">
        <Table className="table">
          <TableHead classes={{ root: 'table-head' }}>
            {tableRowName.map((rowItem: TableItem, index: number) => (
              <TableCell key={index} classes={{ head: 'table-head-cell' }} align="center">
                {rowItem.text}
              </TableCell>
            ))}
            <TableCell classes={{ head: 'table-head-cell actions-head-cell' }} align="right" />
          </TableHead>
          <TableBody>{projectsRows}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default ProjectsTable;
