import { config } from '../config/config';
import { CreateProjectRequest, UpdateProjectRequest } from '../store/projectsSlice';
import axios from './axios';

export const projectsService = {
  getProjects,
  getProjectById,
  createProject,
  updateProject,
};

export async function getProjects() {
  const res = await axios.get(`${config.backendUrl}/projects`);

  return res.data;
}

export async function getProjectById(projectId: string) {
  const res = await axios.get(`${config.backendUrl}/projects/${projectId}`);

  return res.data;
}

export async function createProject(request: CreateProjectRequest) {
  const res = await axios.post(`${config.backendUrl}/projects`, request);

  return res.data;
}

export async function updateProject(request: UpdateProjectRequest) {
  const res = await axios.put(`${config.backendUrl}/projects/${request.projectId}`, request);

  return res.data;
}
