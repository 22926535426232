import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import MainLayout from '../components/common/MainLayout';
import EnvironmentDetail from './EnvironmentDetail';
import Environments from './Environments';
import Login from './Login';
import ProjectDetail from './ProjectDetail';
import Projects from './Projects';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      fontFamily: 'Rubik, sans-serif',
    },
    loginForm: {
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

function Main() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route path="projects" element={<Outlet />}>
            <Route path="" element={<Projects />} />
            <Route path="create" element={<ProjectDetail />} />
            <Route path=":projectId/update" element={<ProjectDetail />} />
            <Route path=":projectId/environments" element={<Environments />} />
            <Route path=":projectId/environments/create" element={<EnvironmentDetail />} />
            <Route path=":projectId/environments/:environmentId/update" element={<EnvironmentDetail />} />
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/projects" replace />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </div>
  );
}

export default Main;
