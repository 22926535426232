import { SyntheticEvent, useState } from 'react';
import { AppBar, Toolbar, Theme, createStyles, Menu, makeStyles } from '@material-ui/core';
import Image from 'material-ui-image';
import { measures } from '../../data/measures';
import { colors } from '../../data/colors';
import { texts } from '../../data/texts';
import { logout } from '../../store/authSlice';
import { useAppDispatch } from '../../store/hooks';
import CustomDialog from './dialogs/CustomDialog';
import CustomButton, { CustomButtonVariant } from './buttons/CustomButton';
import UserCircleIcon from './icons/UserCircleIcon';
import ChevronDown from './icons/ChevronDown';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: { boxShadow: `0 1px 4px ${colors.grays.shadowGray}` },
    root: {
      width: '100%',
      zIndex: theme.zIndex.drawer + 1,
    },
    grow: { flexGrow: 1 },
    toolbar: { alignItems: 'center' },
    title: {
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
      height: measures.headerHeight,
      width: 100,
    },
    logo: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      height: measures.headerHeight,
    },
    rightSide: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    username: { margin: '0 0.6rem' },
    chevronDown: { cursor: 'pointer' },
    menu: {
      marginTop: '52px',
      boxShadow: `0 3px 6px ${colors.grays.shadowGraySmooth}`,
    },
    menuItem: {
      paddingBlock: 10,
      color: colors.grays.subtitleGray,
    },
    buttonWrapper: {
      display: 'block',
      margin: '1.5rem 1rem 1rem',
      width: '100%',
    },
  }),
);

function Header() {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openLogout = () => {
    setOpen(true);
    handleClose();
  };

  const handleClick = (event: SyntheticEvent) => setAnchorEl(event.currentTarget.parentElement);

  const handleClose = () => setAnchorEl(null);

  return (
    <div className={classes.root}>
      <AppBar color="inherit" position="fixed" className={classes.appBar}>
        <Toolbar classes={{ root: classes.toolbar }}>
          <div className={classes.logo}>
            <Image
              imageStyle={{ position: 'inherit', height: 50, width: 'auto' }}
              style={{
                paddingTop: 0,
                background: 'transparent',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: measures.headerHeight,
              }}
              src="https://static.plexo.com.uy/images/header-logo.png"
              alt="logo"
            />
          </div>
          <div className={classes.grow} />
          <div className={classes.rightSide}>
            <UserCircleIcon />
            <span className={classes.username}>username</span>
            <ChevronDown className={classes.chevronDown} onClick={handleClick} />
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              classes={{ paper: classes.menu }}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
              <div className={classes.buttonWrapper}>
                <CustomButton onClick={openLogout} variant={CustomButtonVariant.SECONDARY}>
                  Cerrar sesión
                </CustomButton>
              </div>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <CustomDialog
        open={open}
        setOpen={setOpen}
        title={texts.logoutQuestion}
        cancelContent={texts.cancel}
        proceedButton={{ content: texts.logoutConfirmation, handler: () => dispatch(logout()) }}
      />
    </div>
  );
}

export default Header;
