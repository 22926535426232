import clsx from 'clsx';
import { useEffect } from 'react';
import { NavigateFunction, Params, useNavigate, useParams } from 'react-router-dom';
import Loading from '../components/common/animations/Loading';
import CustomButton, { CustomButtonVariant } from '../components/common/buttons/CustomButton';
import EnvironmentsTable from '../components/environments/EnvironmentsTable';
import { texts } from '../data/texts';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { getProjectById, ProjectsState } from '../store/projectsSlice';
import { AppDispatch, RootState } from '../store/store';
import { Environment } from '../types/environment.types';

function Environments() {
  const navigate: NavigateFunction = useNavigate();
  const params: Readonly<Params<string>> = useParams();
  const dispatch: AppDispatch = useAppDispatch();
  const projects: ProjectsState = useAppSelector((state: RootState) => state.projects);
  const selectedProjectId: string = params.projectId!;

  const backToList = () => navigate('/projects');

  const newEnvironment = () => navigate(`/projects/${selectedProjectId}/environments/create`);

  const openEnvironment = (environment: Environment) => {
    navigate(`/projects/${selectedProjectId}/environments/${environment.environmentId}/update`);
  };

  useEffect(() => {
    if (selectedProjectId) {
      dispatch(getProjectById(selectedProjectId!));
    }
  }, [dispatch, selectedProjectId]);

  return (
    <div className={clsx('environments', 'environments-edit-mode')}>
      {projects.gettingProject || !projects.selectedProject ? (
        <Loading />
      ) : (
        <>
          <div className="environments-title">
            <h3>
              {texts.environments} de {projects.selectedProject!.name}
            </h3>
            <div className="environments-actions">
              <CustomButton onClick={newEnvironment}>{texts.newEnvironment}</CustomButton>
              <CustomButton variant={CustomButtonVariant.SECONDARY} onClick={backToList}>
                {texts.goBack}
              </CustomButton>
            </div>
          </div>
          <EnvironmentsTable
            openEnvironment={openEnvironment}
            projectEnvironments={projects.selectedProject.environments!}
          />
        </>
      )}
    </div>
  );
}

export default Environments;
