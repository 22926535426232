import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { texts } from '../data/texts';
import CustomButton from '../components/common/buttons/CustomButton';
import ProjectsTable from '../components/projects/ProjectsTable';
import { Project } from '../types/project.types';

function Projects() {
  const navigate = useNavigate();

  const newProject = () => navigate('/projects/create');

  const openProject = (project: Project) => navigate(`/projects/${project.projectId}/update`);

  const openEnvironments = (project: Project) => navigate(`/projects/${project.projectId}/environments`);

  return (
    <div className={clsx('projects', 'projects-edit-mode')}>
      <div className="projects-title">
        <h3>{texts.projects}</h3>
        <div className="projects-actions">
          <CustomButton onClick={newProject}>{texts.newProject}</CustomButton>
        </div>
      </div>
      <ProjectsTable openProject={openProject} openEnvironments={openEnvironments} />
    </div>
  );
}

export default Projects;
