import { createStyles, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CustomButton, { CustomButtonVariant } from '../components/common/buttons/CustomButton';
import EnvironmentForm from '../components/environments/EnvironmentForm';
import { texts } from '../data/texts';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { Environment } from '../types/environment.types';

export interface EnvironmentParams {
  environmentId?: string;
}

const useStyles = makeStyles(() => createStyles({ buttons: { display: 'flex', gap: 16 } }));

function EnvironmentDetail() {
  const classes = useStyles();
  const params = useParams<keyof EnvironmentParams>();
  const environments: Environment[] = useAppSelector(
    (state: RootState) => state.projects.selectedProject?.environments!,
  );
  const navigate = useNavigate();
  const [selectedEnvironment, setSelectedEnvironment] = useState<Environment | null>(null);
  const formId: string = 'environment-form-id';

  const cancel = () => navigate(-1);

  useEffect(() => {
    const selectedEnvironmentId = params.environmentId;

    if (environments && selectedEnvironmentId)
      setSelectedEnvironment(
        environments.find((environment) => environment.environmentId === selectedEnvironmentId) || null,
      );
  }, [environments, params.environmentId, selectedEnvironment]);

  return (
    <div className="environment">
      <div className="environment-title">
        <h3>{`${params.environmentId ? 'Editar' : 'Nuevo'} ambiente`}</h3>
        <div className={classes.buttons}>
          <CustomButton type="submit" form={formId}>
            {texts.save}
          </CustomButton>
          <CustomButton onClick={cancel} variant={CustomButtonVariant.SECONDARY}>
            {texts.cancel}
          </CustomButton>
        </div>
      </div>
      <EnvironmentForm selectedEnvironmentId={selectedEnvironment?.environmentId!} formId={formId} />
    </div>
  );
}

export default EnvironmentDetail;
