import { createStyles, Drawer, List, makeStyles, Theme } from '@material-ui/core';
import Image from 'material-ui-image';
import { measures } from '../../../data/measures';
import { colors } from '../../../data/colors';
import { navigation } from '../../../data/navigation';
import SidebarNavigationItems from './SidebarNavigationItems';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      flexShrink: 0,
      width: measures['expandedWidth'],
    },
    paper: {
      webkitTransition: 'width 0.3s ease-in-out',
      transition: 'width 0.3s ease-in-out',
      width: measures['expandedWidth'],
      backgroundColor: colors.white,
      borderRight: 'none',
    },
    toolbar: theme.mixins.toolbar,
    sidebar: {
      padding: '0.6rem 1rem 0.6rem 0',
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    powered: {
      padding: '1rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: colors.grays.inputGray,
      fontSize: '12px',
    },
    listItemIcon: { minWidth: 40 },
    sidebarText: {
      fontSize: 14,
      marginLeft: 8,
    },
  }),
);

function Sidebar() {
  const classes = useStyles();

  return (
    <Drawer className={classes.drawer} variant="permanent" classes={{ paper: classes.paper }}>
      <div className={classes.toolbar} />
      <div className={classes.sidebar}>
        <List>
          <SidebarNavigationItems navigationArray={navigation} />
        </List>
        <div className={classes.powered}>
          Powered by{' '}
          <Image
            imageStyle={{ position: 'inherit', height: 35, width: 'auto' }}
            style={{ paddingTop: 0, marginLeft: 8 }}
            src={'https://static.plexo.com.uy/images/header-logo.png'}
            alt="logo"
          />
        </div>
      </div>
    </Drawer>
  );
}

export default Sidebar;
