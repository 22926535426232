import { texts } from './texts';

export interface NavigationObject {
  name: string;
  to: string;
}

const navigation: NavigationObject[] = [
  {
    name: texts.projects,
    to: '/projects',
  },
];

export { navigation };
