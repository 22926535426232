import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { environmentsService } from '../services/environments';
import { Environment } from '../types/environment.types';
import { logout } from './authSlice';
import { getProjectById } from './projectsSlice';

export interface CreateEnvironmentRequest {
  url: string;
  alias: string;
  projectId: string;
}

export interface UpdateEnvironmentRequest {
  url: string;
  alias: string;
  environmentId: string;
}

export interface EnvironmentsState {
  creatingEnvironment: boolean;
  createEnvironmentSuccess: boolean;
  createEnvironmentErrorMessage: string | null;
  updatedEnvironment: Environment | null;
  updatingEnvironment: boolean;
  updateEnvironmentSuccess: boolean;
  updateEnvironmentErrorMessage: string | null;
}

const initialState: EnvironmentsState = {
  updatedEnvironment: null,
  creatingEnvironment: false,
  createEnvironmentSuccess: false,
  createEnvironmentErrorMessage: null,
  updatingEnvironment: false,
  updateEnvironmentSuccess: false,
  updateEnvironmentErrorMessage: null,
};

export const createEnvironment = createAsyncThunk(
  'createEnvironment',
  async (data: CreateEnvironmentRequest) => {
    const response = await environmentsService.createEnvironment(data);

    return response.data;
  },
);

export const updateEnvironment = createAsyncThunk(
  'updateEnvironment',
  async (data: UpdateEnvironmentRequest) => {
    const response = await environmentsService.updateEnvironment(data);

    return response.data;
  },
);

const environmentsSlice = createSlice({
  name: 'environments',
  initialState,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<EnvironmentsState>) => {
    builder.addCase(createEnvironment.pending, (state: EnvironmentsState, _action) => {
      state.creatingEnvironment = true;
      state.createEnvironmentSuccess = false;
      state.createEnvironmentErrorMessage = null;
    });
    builder.addCase(createEnvironment.fulfilled, (state: EnvironmentsState, _action) => {
      state.creatingEnvironment = false;
      state.createEnvironmentSuccess = true;
      state.createEnvironmentErrorMessage = null;
    });
    builder.addCase(createEnvironment.rejected, (state: EnvironmentsState, action) => {
      state.creatingEnvironment = false;
      state.createEnvironmentSuccess = false;
      state.createEnvironmentErrorMessage = action.error.message!;
    });
    builder.addCase(updateEnvironment.pending, (state: EnvironmentsState, _action) => {
      state.updatingEnvironment = true;
      state.updateEnvironmentSuccess = false;
      state.updateEnvironmentErrorMessage = null;
    });
    builder.addCase(updateEnvironment.fulfilled, (state: EnvironmentsState, action) => {
      state.updatedEnvironment = action.payload;
      state.updatingEnvironment = false;
      state.updateEnvironmentSuccess = true;
      state.updateEnvironmentErrorMessage = null;
    });
    builder.addCase(updateEnvironment.rejected, (state: EnvironmentsState, action) => {
      state.updatingEnvironment = false;
      state.updateEnvironmentSuccess = false;
      state.updateEnvironmentErrorMessage = action.error.message!;
    });
    builder.addCase(getProjectById.fulfilled, () => initialState);
    builder.addCase(logout.fulfilled, () => initialState);
  },
});

const { reducer } = environmentsSlice;

export default reducer;
